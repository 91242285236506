import React, { useEffect, useState } from "react";
import x from "../../images/arrow-left-line.png";
import line3 from "../../images/Line 3.png";
import BASE_URL from "../config/config";

const Bank = ({ onClose }) => {
  const [userData, setUserData] = useState(null);
  const [bankName, setBankName] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [recentBanks, setRecentBanks] = useState([]);
  const [bankType, setBankType] = useState("");
  const [token, setToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [creditCardDetails, setCreditCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [debitCardDetails, setDebitCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    accountNumber: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (token) {
          setToken(token);
          const response = await fetch(`${BASE_URL}api/userdata`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          setUserData(data);
        } else {
          alert("Login Failed", "Token not found");
        }
      } catch (error) {
        console.error("Error retrieving user data:", error);
        alert("Error", error.message);
      }
    };

    fetchData();
  }, []);

  // Function to handle change in select input for bank type
  const handleBankTypeChange = (e) => {
    setBankType(e.target.value);
    // Reset card details when changing bank type
    setCreditCardDetails({
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    });
    setDebitCardDetails({
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      accountNumber: "",
    });
  };

  // Function to handle change in credit card details input
  const handleCreditCardChange = (e) => {
    const { name, value } = e.target;
    setCreditCardDetails({
      ...creditCardDetails,
      [name]: value,
    });
  };

  // Function to handle change in debit card details input
  const handleDebitCardChange = (e) => {
    const { name, value } = e.target;
    setDebitCardDetails({
      ...debitCardDetails,
      [name]: value,
    });
  };

  // Render UPI ID input only if bankType is not credit card or debit card
  const renderUPIIDInput = () => {
    if (bankType !== "credit card" && bankType !== "debit card") {
      return (
        <>
          <label className="lvl-pop">UPI ID</label>
          <div className="inputContainer new-size">
            <input
              className="input"
              placeholder="Enter UPI ID"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </div>
        </>
      );
    }
    return null;
  };

  // Function to render back button when credit card or debit card option is selected
  const renderBackButton = () => {
    if (bankType === "credit card" || bankType === "debit card") {
      return (
        <button className="backButton" onClick={() => setBankType("")}>
          Back
        </button>
      );
    }
    return null;
  };

  return (
    <>
      <div className="overlay">
        <div className="overlay-content">
          <div className="head-addmoney">
            <h2>Add Bank Details</h2>
            <img src={x} onClick={onClose} alt="Close" />
          </div>
          <img className="vertical newone" src={line3} alt="line" />

          <div className="">
            {renderBackButton()} {/* Render back button conditionally */}
            <label className="lvl-pop">Bank</label>
            <div className="inputContainer new-size">
              <select
                className="picker"
                value={bankType}
                onChange={handleBankTypeChange}
              >
                <option value="">Select Payment Method</option>
                <option value="credit card">Credit Card</option>
                <option value="debit card">Debit Card</option>
                {/* Add more options as needed */}
              </select>
            </div>

            {bankType === "credit card" && (
              <>
                <label className="lvl-pop">Credit Card Number</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter Credit Card Number"
                    name="cardNumber"
                    value={creditCardDetails.cardNumber}
                    onChange={handleCreditCardChange}
                  />
                </div>

                <label className="lvl-pop">Expiry Date</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter Expiry Date"
                    name="expiryDate"
                    value={creditCardDetails.expiryDate}
                    onChange={handleCreditCardChange}
                  />
                </div>

                <label className="lvl-pop">CVV</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter CVV"
                    name="cvv"
                    value={creditCardDetails.cvv}
                    onChange={handleCreditCardChange}
                  />
                </div>
              </>
            )}

            {bankType === "debit card" && (
              <>
                <label className="lvl-pop">Debit Card Number</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter Debit Card Number"
                    name="cardNumber"
                    value={debitCardDetails.cardNumber}
                    onChange={handleDebitCardChange}
                  />
                </div>

                <label className="lvl-pop">Expiry Date</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter Expiry Date"
                    name="expiryDate"
                    value={debitCardDetails.expiryDate}
                    onChange={handleDebitCardChange}
                  />
                </div>

                <label className="lvl-pop">CVV</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter CVV"
                    name="cvv"
                    value={debitCardDetails.cvv}
                    onChange={handleDebitCardChange}
                  />
                </div>

                <label className="lvl-pop">Account Number</label>
                <div className="inputContainer new-size">
                  <input
                    className="input"
                    placeholder="Enter Account Number"
                    name="accountNumber"
                    value={debitCardDetails.accountNumber}
                    onChange={handleDebitCardChange}
                  />
                </div>
              </>
            )}

            {/* Render UPI ID input */}
            {renderUPIIDInput()}
          </div>

          <button
            className={`loginButton ${isLoading ? "disabledButton" : ""}`}
            disabled={isLoading}
          >
            {isLoading ? <div className="spinner"></div> : "Add Payment"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Bank;
