import img1 from '../images/data-img2.png'
import img2 from '../images/data-img5.png'

import game1 from '../images/data-img1.png'
import game2 from '../images/data-img2.png'
import game3 from '../images/data-img3.png'
import game4 from '../images/data-img4.png'
import game5 from '../images/data-img5.png'
import game6 from '../images/data-img6.png'

export const games = [
    {
        img: game1,
        heading: "core philosophies",
        playerimg: img1,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    },
    {
        img: game2,
        heading: "core philosophies",
        playerimg: img1,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    }, {
        img: game3,
        heading: "core philosophies",
        playerimg: img2,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    },
    {
        img: game4,
        heading: "core philosophies",
        playerimg: img1,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    },
    {
        img:game5,
        heading: "core philosophies",
        playerimg: img2,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    }, {
        img:game6,
        heading: "core philosophies",
        playerimg: img1,
        playername: "Cameron Williamson",
        gamename: "Gillette",
        button: "Live demo"
    },

]