import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config/config";
import line4 from "../../images/line4.png";
import backlogo from "../../images/backlogo.png"
import bigline from "../../images/bigLine 4.png";
const History = ({ onBackClick }) => {
    const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      console.log("Authentication token not found.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/transactions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setTransactions(data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    }
    setLoading(false);
  };
  const renderItem = (item) => {
    const transactionTypeStyle =
      item.transactionType === "credit" ? "credit" : "debit";

    return (
      <div className={`itemContainer ${transactionTypeStyle}`} key={item._id}>
        <div className="tran_cont">
          <p className="text">
            ({item.transactionType}): {item.description}
            <p className="dateText">
            {new Date(item.transactionDate).toLocaleDateString()}
          </p>
          </p>
          <div className="inr-div">
          <p >INR</p>
          <p> {item.amount.toFixed(2)}</p>
          </div>
        </div>

        <div className="tran_cont">
         
        </div>
      </div>
    );
  };
  return (
    <div className="super-main-wallet">
      <img src={bigline} alt="background" />
      <div className="mainView2">
      <div className="backgroundStyle" />
      <div className="safeArea">
      
          
     <div className="backlogo-heading">
          <img onClick={onBackClick} src={backlogo} alt="Back" className="icon absolute" />
          <h1 >Transactions</h1>
          </div>
          <img src={line4} alt="line" />
        <div style={{ paddingHorizontal: 16 }}>
          {loading ? (
            <p className="loadingText">Loading...</p>
          ) : (
            <div className="listContent">
              {transactions.map(renderItem)}
            </div>
          )}
        </div>
      </div>
    </div>
    
    </div>
  );
};

export default History;
