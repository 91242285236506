import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import './css/Joinroom.css';
import BASE_URL from "./config/config";

const JoinRoomScreen = () => {
  const [roomID, setRoomID] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState('');
  const [recentRooms, setRecentRooms] = useState([]);
  const navigate = useNavigate();

  const toggleCheckbox = () => {
    setChecked(!isChecked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (token) {
          setToken(token);
          const response = await fetch(`${BASE_URL}api/userdata`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserData(data);
        } else {
          console.log('Token not found');
        }
      } catch (error) {
        console.error('Error retrieving user data:', error);
      }
    };
    fetchData();
  }, []);

  const joinRoom = async (item) => {

    try {
      const token = await localStorage.getItem("userToken");
      const response = await fetch(`${BASE_URL}join-room`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ roomID: roomID }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Joined Room:", data.room);
       
        alert("join succesfully")

        // Update recent rooms with joined room data
        setRecentRooms((prevRooms) =>
          prevRooms.map((room) =>
            room.roomID === item.roomID
              ? {
                  ...room,
                  members: room.members
                    ? [...room.members, userData.uid]
                    : [userData.uid],
                }
              : room
          )
        );
      } else {
        alert("Error", data.message || "Failed to join room");
      }
    } catch (error) {
      console.log("Error joining room:", error);
      alert("Error", "Failed to join room");
    }
  };

  return (
    <div className="container">
      <button className="backButton" onClick={() => navigate(-1)}>
        <IonIcon icon="arrow-back" size="large" color="#fff" />
      </button>
      <h1 className="title">Join the Room</h1>
      <div className="inputContainer">
        <div className="input">
          <p className="inputText">
            {userData ? userData.uid : 'Loading...'}
          </p>
        </div>
      </div>
      <div className="inputContainer">
        <label className="label">Room ID</label>
        <input
          className="textInput"
          placeholder="Enter Room id"
          value={roomID}
          onChange={(e) => setRoomID(e.target.value)}
        />
      </div>
      <div className="checkboxContainer" onClick={toggleCheckbox}>
        <IonIcon icon={isChecked ? 'checkbox-outline' : 'square-outline'} size="large" color="#fff" />
        <p className="checkboxLabel">I agree to the Terms & Conditions</p>
      </div>
      <button className="button" onClick={joinRoom} disabled={!isChecked}>
        <p className="buttonText">JOIN ROOM</p>
      </button>
    </div>
  );
};

export default JoinRoomScreen;
