import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./config/config";
import "./css/Withdraw.css";

const Withdraw = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(null);

  const quickAmounts = [100, 500, 1000, 2000, 5000];

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const fetchWalletBalance = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      alert("Error: You must be logged in to perform this action.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/userdata`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setWalletBalance(data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      alert("Error: Failed to fetch wallet balance.");
    }
  };

  const spendMoney = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      alert("Error: You must be logged in to perform this action.");
      return;
    }

    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      alert("Invalid Input: Please enter a valid amount.");
      return;
    }

    if (numericAmount > walletBalance) {
      alert("Error: You do not have enough balance for withdrawal.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}api/spend`, {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amount: numericAmount }),
      });
      const data = await response.json();
      alert("Success: Money spent successfully!");
      setAmount("");
      setWalletBalance(data.newBalance);
    } catch (error) {
      console.error("Error spending money:", error);
      alert("Error: Failed to spend money.");
    } finally {
      setLoading(false);
    }
  };

  const fillAmount = (value) => {
    setAmount((prevAmount) => {
      const numericPrevAmount = parseFloat(prevAmount) || 0;
      return (numericPrevAmount + value).toString();
    });
  };

  return (
    <div className="mainView">
      <div className="backgroundStyle" />
      <div className="safeArea">
        <div className="headerContainer">
          <h1 className="header">Withdraw Money</h1>
        </div>
        <img
          src={require("../assets/Line.png")}
          style={{ marginTop: 0, alignSelf: "center" }}
          alt="Separator"
        />
        <div className="balanceContainer">
          <p className="balanceText">
            Wallet Balance: {walletBalance !== null ? walletBalance.toFixed(2) : "0.00"}
          </p>
        </div>
        <div className="inputContainer">
          <img src={require("../assets/coin.png")} className="icon" alt="Coin" />
          <input
            className="input"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
            min="0"
          />
        </div>
        <p className="quickText">Quick Select</p>
        <div className="quickFillRow">
          {quickAmounts.map((item) => (
            <button
              key={item}
              className="quickFillButton"
              onClick={() => fillAmount(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          className={`loginButton ${loading ? 'disabledButton' : ''}`}
          onClick={spendMoney}
          disabled={loading}
        >
          {loading ? <div className="spinner" /> : <span>Withdraw Money</span>}
        </button>
        <div className="linkcont">
          <button onClick={() => navigate(-1)} className="linkButton">
            Back to Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
