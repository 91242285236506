import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../components/css/Adminroom.css";
import Header from "./Header";
import backlogo from "../images/backlogo.png";
import line from "../images/adminline2.png";
import mic from "../images/Mic.png";
import mikeline from "../images/mikeline.png";
import linever from "../images/Linever.png";
import ImageCarousel from "./Admincrousel";
import small1 from "../images/small1.png";
import small2 from "../images/small2.png";
import small3 from "../images/small3.png";
import History from "./userdetail/History";
import Deposit2 from "./userdetail/Deposite2";
import Withdraw3 from "./userdetail/Withdraw3";
import smallline from "../images/smallline.png";
import game1 from "../images/Rectangle 2.png";
import game2 from "../images/Rectangle 3.png";
import game3 from "../images/Rectangle 4.png";
import game4 from "../images/Rectangle 5.png";
import Footer from "./Footer";
import  gsap  from "gsap";
 import { useRef } from "react";
 import { useGSAP } from "@gsap/react";
 import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(useGSAP);

const BASE_URL = "http://your-backend-url.com/";

const Adminroom = () => {
  const [userData, setUserData] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [showDepositOverlay, setShowDepositOverlay] = useState(false);
  const [showWithdrawOverlay, setShowWithdrawOverlay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const room = location.state.room;
  const textheadRef=useRef(null)
  const cus1Ref=useRef(null)
  const cus2Ref=useRef(null)
  const cus3Ref=useRef(null)


  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      alert("Please log in");
      navigate(-1);
    }
  }, [navigate]);

  const onBackClick = () => {
    window.history.back();
  };

  const gamesarray = [
    { img: game1 },
    { img: game2 },
    { img: game3 },
    { img: game4 },
  ];

 


  useGSAP(()=>{
    const texthead=textheadRef.current
    const cus1=cus1Ref.current
    const cus2=cus2Ref.current
    const cus3=cus3Ref.current


    gsap.from(texthead, {
      x:-200,
      opacity:0,
      duration: 1,
      smooth:true,
      scrollTrigger:{
          trigger:texthead,
          markers:false,
          start:"top 100%"
      }  
    });

    gsap.from(cus1, {
        x:400,
        opacity:0,
        duration: 1,
        scrollTrigger:{
            trigger:cus1,
            markers:false,
            start:"top 90%"
        }  
      });

      gsap.from(cus2, {
        scale:0,
        opacity:0,
        duration: 1,
        scrollTrigger:{
            trigger:cus2,
            markers:false,
            start:"top 100%"
        }  
      });

      gsap.from(cus3, {
        x:-400,
        opacity:0,
        duration: 1,
        scrollTrigger:{
            trigger:cus3,
            markers:false,
            start:"top 90%"
        }  
      });

})


const printdata = gamesarray.map((item, index) => (
  <div ref={cus1Ref} className="games-array" key={index}>
    <img src={item.img} alt={`game-${index}`} />
  </div>
));



  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="main-admin-wrapper">
        <div className="admin-main">
          <div className="back-btn">
            <h2
              onClick={onBackClick}
              src={backlogo}
              alt="Back"
              className="icon absolute addd"
            >Back</h2>
            {room.roomName}
          </div>
          <div className="id-btn-wraper">
            <div className="room-id">Room Id: {room.roomID} </div>
            <button className="delete-room">DELETE ROOM</button>
          </div>
        </div>

        <img className="admin-line" src={line} />

        <div className="all-contenet-div">
          <div className="mike-all-conetnt">
            <div className="mike">
              <div className="min-div-img">
                <img src={mic} alt="Mic" />
              </div>
              <select className="mike-select">
                <option>Members 1</option>
                <option>Members 2</option>
                <option>Members 3</option>
              </select>
            </div>

            <img src={mikeline} alt="Mike Line" />

            <div className="mike-div2">
              <p>Available Room Balance</p>
              <h2>50,684.89*</h2>
            </div>

            <div className="mike-div3">
              <p>Main Wallet Balance</p>
              <h2>140,405.05</h2>
            </div>
          </div>
          <img className="hide-div" src={linever} alt="Line Vertical" />

          <div className="content-2">
            <ImageCarousel />
            <div className="small-logos-div  new-pad">
              <div className="small-div" onClick={() => setShowDepositOverlay(true)}>
                <img src={small1} alt="Deposit" />
                <p>Deposit</p>
              </div>
              <img src={smallline} alt="Small Line" />
              <div className="small-div" onClick={() => setShowWithdrawOverlay(true)}>
                <img src={small2} alt="Withdraw" />
                <p>Withdraw</p>
              </div>
              <img src={smallline} alt="Small Line" />
              <div className="small-div" onClick={() => setShowHistory(true)}>
                <img src={small3} alt="History" />
                <p>History</p>
              </div>
            </div>
          </div>
        </div>
        <img className="admin-line2" src={line} />
      </div>

      <div className="admin-games-div">
        <h1>Games</h1>
        <div className="games-div">{printdata}</div>
      </div>

      {showDepositOverlay && <Deposit2 onClose={() => setShowDepositOverlay(false)} />}
      {showWithdrawOverlay && <Withdraw3 onClose={() => setShowWithdrawOverlay(false)} />}
      {/* {showHistory && <History onClose={() => setShowHistory(false)} />} */}

      <Footer />
    </>
  );
};

export default Adminroom;
