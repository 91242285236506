import React, { useState, useEffect } from "react";
import { FaFeather, IoIoniconsIcons } from "react-icons/fa";

import BASE_URL from "./config/config";

const Createroom = () => {
  const [userData, setUserData] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [roomID, setRoomID] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [recentRooms, setRecentRooms] = useState([]);
  const [roomType, setRoomType] = useState("");
  const [token, setToken] = useState("");

  const roomTypes = [
    { label: "Select your room type", value: "" },
    { label: "4 Members - 10,000 INR", value: "4_members_10000_inr" },
    { label: "8 Members - 20,000 INR", value: "8_members_20000_inr" },
    { label: "12 Members - 30,000 INR", value: "12_members_30000_inr" },
    { label: "16 Members - 40,000 INR", value: "16_members_40000_inr" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (token) {
          setToken(token);
          const response = await fetch(`${BASE_URL}api/userdata`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          setUserData(data);
        } else {
          alert("Login Failed", "Token not found");
        }
      } catch (error) {
        console.error("Error retrieving user data:", error);
        alert("Error", error.message);
      }
    };

    fetchData();
  }, []);

  const generateUniqueRoomID = () => {
    const characters = "0123456789";
    const groups = [];
    const groupSize = 3;
    const delimiter = "-";

    for (let i = 0; i < groupSize * 3; i += groupSize) {
      const group = characters
        .slice(i, i + groupSize)
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
      groups.push(group);
    }

    const formattedRoomID = `RID-${groups.join(delimiter)}`;
    console.log("Generated Room ID:", formattedRoomID);
    setRoomID(formattedRoomID);
  };

  // useEffect(() => {
  //   generateUniqueRoomID();
  // }, []);

  
  const createRoom = async () => {
    if (!termsAccepted) {
      alert("You must agree to the terms and conditions to create a room.");
      return;
    }
  
    if (!roomType) {
      alert("Please select a room type.");
      return;
    }
  
    try {
      const roomTypeParts = roomType.split("_");
      const totalMembers = parseInt(roomTypeParts[0]); // Extract the total number of members
      const members = [`${1}/${totalMembers}`]; // Format the members as "1/totalMembers"
  
      let response;
      let data;
      let newRoomID;
  
      do {
        // Generate a new room ID (if needed)
        newRoomID = roomID;
  
        // Check if the room ID already exists
        response = await fetch(`${BASE_URL}create-room`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            uid: userData ? userData.uid : null,
            roomID: newRoomID,
            roomName: roomName,
            roomType: roomType,
            members: members,
          }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          // Retry the process if room ID already exists
          if (errorData.message === "Room ID already exists") {
            console.log("Room ID already exists, retrying...");
            // Set new room ID if required
            // setRoomID(newRoomID);
          } else {
            throw new Error(errorData.message || "Failed to create room");
          }
        } else {
          data = await response.json();
        }
      } 
      while (!response.ok); // Retry until a unique room ID is generated
  
      console.log("Room created successfully:", data);
      // navigation.navigate("adminroom", { roomID: data.room.roomID });
    } catch (error) {
      console.error("Error creating room:", error);
      alert("Error Creating Room", error.message || "Failed to create room");
    }
  };

  const joinRoom = async (item) => {
    try {
      const response = await fetch(`${BASE_URL}/join-room/${item.roomID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Joined Room:", data.room);

        setRecentRooms((prevRooms) =>
          prevRooms.map((room) =>
            room.roomID === item.roomID
              ? { ...room, members: [...room.members, userData.uid] }
              : room
          )
        );
      } else {
        alert("Error", data.message || "Failed to join room");
      }
    } catch (error) {
      console.error("Error joining room:", error);
      alert("Error", "Failed to join room");
    }
  };

  const renderItem = ({ item }) => (
    <div className="room-card">
      <div>
        <div className="room-name">{item.name}</div>
        <div className="room-details">Room ID: {item.roomID}</div>
        <div className="room-details">
          Members: {item.members.length}/{item.capacity}
        </div>
      </div>
      <button className="join-button" onClick={() => joinRoom(item)}>
        Join
      </button>
    </div>
  );

  const shareRoom = () => {
    if (roomID) {
      const deepLink = `superwinApp://room/${roomID}`;
      navigator.share({
        title: "Join my room!",
        text: `Join my room! ${deepLink}`,
      });
    } else {
      alert("Please generate a Room ID first.");
    }
  };
  return(

      <div className="container">
        <div className="scrollViewContent">
          {/* <button onClick={() => navigation.goBack()} className="backButton"> */}
   
          {/* </button> */}
          <h1 className="header">Room</h1>
          <div className="inputContainer">
            <label className="label">UID (this is your UID, you cannot change this)</label>
            <div className="inputWrapper">
              <img src={require("../assets/PersonFill.png")} alt="Icon" className="icon" />
              <p className="inputText">{userData ? userData.uid : "Loading..."}</p>
            </div>
            <div className="inputWrapper">
              <img src={require("../assets/PersonFill.png")} alt="Icon" className="icon" />
              <input
                className="input disabledInput"
                placeholder="Room ID"
                value={roomID}
                onChange={(e) => setRoomID(e.target.value)}
                disabled
              />
              <button onClick={ generateUniqueRoomID}>generate roomid</button>
              <button onClick={generateUniqueRoomID} className="backButton">
                <span className="label">Room ID</span>
              </button>
              <button onClick={shareRoom} className="shareButton">
               
              </button>
            </div>
            <label className="label">Room Name</label>
            <div className="inputWrapper">
              <img src={require("../assets/PersonFill.png")} alt="Icon" className="icon" />
              <input
                className="input"
                placeholder="Enter Room Name"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
            </div>
            <label className="label">Room Type</label>
            <div className="inputWrapper">
              <img src={require("../assets/PersonFill.png")} alt="Icon" className="icon" />
              <select
                className="picker"
                value={roomType}
                onChange={(e) => setRoomType(e.target.value)}
              >
                {roomTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="termsContainer">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label className="termsText">I agree to the Terms & Conditions</label>
            </div>
          </div>
          <button onClick={createRoom} className="createRoomButton">
            
              <span className="createRoomButtonText">+ CREATE A ROOM</span>
          
          </button>
          <div className="recentRoomsList">
            {recentRooms.map(renderItem)}
          </div>
        </div>
      </div>
  
    
  )

}
export default Createroom