import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./config/config";
import "./css/Transactions.css";

const Transactions = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      console.log("Authentication token not found.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/transactions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setTransactions(data);
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    }
    setLoading(false);
  };

  const renderItem = (item) => {
    const transactionTypeStyle =
      item.transactionType === "credit" ? "credit" : "debit";

    return (
      <div className={`itemContainer ${transactionTypeStyle}`} key={item._id}>
        <div className="tran_cont">
          <p className="text">
            ({item.transactionType}): {item.description}
          </p>
          <p className="text">INR: {item.amount.toFixed(2)}</p>
        </div>

        <div className="tran_cont">
          <p className="dateText">
            {new Date(item.transactionDate).toLocaleDateString()}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="mainView">
      <div className="backgroundStyle" />
      <div className="safeArea">
        <div className="headerContainer">
          <button
            className="backButton"
            onClick={() => navigate(-1)}
          >
            <img src={require("../assets/back.png")} className="icon" alt="Back" />
          </button>
          <h1 className="header">Transactions</h1>
        </div>
        <img
          src={require("../assets/Line.png")}
          style={{ margin: 10, alignSelf: "center" }}
          alt="Separator"
        />
        <div style={{ paddingHorizontal: 16 }}>
          {loading ? (
            <p className="loadingText">Loading...</p>
          ) : (
            <div className="listContent">
              {transactions.map(renderItem)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
