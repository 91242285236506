import React, { useEffect, useState } from "react";
import "./css/Withdraw2.css"
import BASE_URL from "../config/config";
import { useNavigate } from "react-router-dom";
import line3 from "../../images/Line 3.png";
import reaclcoin from "../../images/realcoin.png"
import x from "../../images/arrow-left-line.png"
const Withdraw = ({ onClose }) => {
    const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(null);

  const quickAmounts = [100, 500, 1000, 2000, 5000];

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const fetchWalletBalance = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      alert("Error: You must be logged in to perform this action.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/userdata`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setWalletBalance(data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      alert("Error: Failed to fetch wallet balance.");
    }
  };

  const spendMoney = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      alert("Error: You must be logged in to perform this action.");
      return;
    }

    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      alert("Invalid Input: Please enter a valid amount.");
      return;
    }

    if (numericAmount > walletBalance) {
      alert("Error: You do not have enough balance for withdrawal.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}api/spend`, {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amount: numericAmount }),
      });
      const data = await response.json();
      alert("Success: Money spent successfully!");
      setAmount("");
      setWalletBalance(data.newBalance);
    } catch (error) {
      console.error("Error spending money:", error);
      alert("Error: Failed to spend money.");
    } finally {
      setLoading(false);
    }
  };

  const fillAmount = (value) => {
    setAmount((prevAmount) => {
      const numericPrevAmount = parseFloat(prevAmount) || 0;
      return (numericPrevAmount + value).toString();
    });
  };
  return (
    <div className="overlay">
      <div className="overlay-content">
        <div className="head-addmoney">
            <h2>Withdraw Money</h2>
            <img src={x}onClick={onClose}/>
        </div>
        <img className="vertical newone" src={line3} alt="line"/>
        <div className="inputContainer">
          <img src={reaclcoin} alt="coin" className="icon " />
          <input
            className="input wid"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
          />
        </div>
          <div className="quickFillRow">
          {quickAmounts.map((item) => (
            <button
              key={item}
              className="quickFillButton height"
              onClick={() => fillAmount(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          className={`loginButton ${loading ? 'disabledButton' : ''}`}
          onClick={spendMoney}
          disabled={loading}
        >
          {loading ? <div className="spinner" /> : <span>Withdraw Money</span>}
        </button>
       
      </div>
    </div>
  );
};

export default Withdraw;
