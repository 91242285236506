import React, { useEffect, useState } from "react";
import "./css/Wallet.css";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import BASE_URL from "../config/config";
import line4 from "../../images/line4.png";
import uparrow from "../../images/up-arrow.png";
import downarrow from "../../images/down-arrow.png";
import small1 from "../../images/small1.png";
import small2 from "../../images/small2.png";
import small3 from "../../images/small3.png";
import bigline from "../../images/bigLine 4.png";
import lineacc from "../../images/accline.png";
import smallline from "../../images/smallline.png";
import History from "./History";
import Deposit from "./Deposite";
import Withdraw from "./Withdraw2";
import Bank from "./Bank";

const Wallet = () => {
  const [userData, setUserData] = useState(null);
  const [walletBalance, setWalletBalance] = useState("0");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showOverlay, setShowOverlay] = useState(null); // Updated to handle overlays

  const navigate = useNavigate();
  const avatars = {
    avatar_1: require("../../assets/avatar/avatar_1.png"),
    avatar_2: require("../../assets/avatar/avatar_2.png"),
    avatar_3: require("../../assets/avatar/avatar_3.png"),
    avatar_4: require("../../assets/avatar/avatar_4.png"),
    avatar_5: require("../../assets/avatar/upload_avatar.png"),
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    setIsLoggedIn(!!token);
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserData(decoded);
        console.log("Decoded user data:", decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        try {
          const response = await fetch(`${BASE_URL}api/userdata`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          console.log("Fetched wallet balance data:", data);
          if (data && typeof data.walletBalance === 'number') {
            setWalletBalance(data.walletBalance.toFixed(2));
          } else {
            console.error("Invalid wallet balance data:", data);
            setWalletBalance("0.00");
          }
        } catch (error) {
          console.error("Error fetching wallet balance:", error);
          setWalletBalance("0.00");
        }
      }
    };
    fetchWalletBalance();

    
    const intervalId = setInterval(fetchWalletBalance, 50000);

    return () => clearInterval(intervalId); 
  }, []);

  const handleBackClick = () => {
    setShowHistory(false);
  };

  const handleOverlayClose = () => {
    setShowOverlay(null);
  };

  if (!userData) {
    return <p>Loading...</p>;
  }

  if (showHistory) {
    return <History onBackClick={handleBackClick} />;
  }

  return (
    <div className="super-main-wallet">
      {showOverlay === 'deposit' && <Deposit onClose={handleOverlayClose} />}
      {showOverlay === 'withdraw' && <Withdraw onClose={handleOverlayClose} />}
      {showOverlay === 'bank' && <Bank onClose={handleOverlayClose} />}

      <img src={bigline} alt="background" />
      <div className="wallet-main">
        <h2>Hello, {userData.name}</h2>
        <img src={line4} alt="line" />
        <div className="wallet-detail">
          <div className="available-blc">
            <p>Available Balance</p>
            <h1>{walletBalance}*</h1>
            <div className="mix">
              <p className="blur">available for Withdraw</p>
              <p className="big-g blur">4000</p>
            </div>
          </div>
          <img className="w-line" src={lineacc} alt="lineacc" />
          <div className="total-wins width">
            <img src={uparrow} alt="uparrow" />
            <p className="wins-acc">Total Wins</p>
            <p>INR 40968</p>
          </div>
          <div className="total-wid width">
            <img src={downarrow} alt="downarrow" />
            <p className="wins-acc">Total Withdraw</p>
            <p>$ 60968</p>
          </div>
        </div>

        <div className="account-offers">
          <div className="quick-action">
            <p>Quick Action</p>
            <div className="small-logos-div">
              <div className="small-div" onClick={() => setShowOverlay('deposit')}>
                <img src={small1} alt="deposit" />
                <p>Deposit</p>
              </div>
              <img src={smallline} alt="smallline" />
              <div className="small-div" onClick={() => setShowOverlay('withdraw')}>
                <img src={small2} alt="withdraw" />
                <p>Withdraw</p>
              </div>
              <img src={smallline} alt="smallline" />
              <div className="small-div" onClick={() => setShowHistory(true)}>
                <img src={small3} alt="history" />
                <p>History</p>
              </div>
            </div>
            <p>Bank Details</p>
            <div className="acc-num">
              <p>Account Number</p>
              <hr className="custom-hr" />
              <p>Hdfc Bank</p>
              <p>Account No: 343***********34234</p>
              <p>IFSC Code: HDFC45674</p>
            </div>
            <div className="upi-div">
              <p>UPI</p>
            </div>
            <button className="acc-btn" onClick={() => setShowOverlay('bank')}>ADD BANK DETAIL</button>
          </div>

          <div className="promo">
            <p>Promo & Discount</p>
            <div className="today-offers1">
              <p className="small-width">Special Offer for Today's Top Up</p>
              <p className="setsize">Get discount for every top up, transfer and payment</p>
            </div>
            <div className="today-offers1">
              <p className="small-width">Special Offer for Today's Top Up</p>
              <p className="setsize">Get discount for every top up, transfer and payment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
