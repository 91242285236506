import React, { useState } from "react";
import "./css/Support.css"
import { faqs } from "../../data/Faqdata";
import bigline from  "../../images/bigLine 4.png"
import {
    FaChevronDown,
    FaChevronUp,
  } from "react-icons/fa";

const Support=()=>{
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
return(<>
<div className="main-con size-top">
<img className="line-img" src={bigline}/>
<div className="faq-container">
          
          {faqs.map((faq, index) => (
            <React.Fragment key={index}>
              {index === 0 && <h2>Deposits and Withdrawals</h2>}
              {(index > 0 && index % 5 === 0) && <h2>General Questions</h2>}
              <div
                className={`faq-item size-top${activeIndex === index ? "active" : ""}`}
                key={index}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.n}
                  <p>{faq.question}</p>
                  <span>
                    {activeIndex === index ? <FaChevronUp  /> : <FaChevronDown className="icon-arrow"/>}
                  </span>
                </div>
                <div
                  className={`faq-answer ${activeIndex === index ? "show" : ""}`}
                >
                  <hr className="vertical-line"/>
                  <p>{faq.answer}</p>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        </div>
</>)
}

export default Support