import React, { useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import BASE_URL from "./config/config"; // Ensure this path is correct
import "./css/VerifyOtpPage.css"; // Import CSS styles
import backbtn from "../images/backlogo.png"; // Import images
import logo from "../images/logo superwin.png";
import backlogo from "../images/backlogo.png"
export default function VerifyOtpPage() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const navigate = useNavigate();
  const location = useLocation();
  const { phoneNumber } = location.state;


  const handleSendOtp = async () => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (!/^(\+?\d{1,3})?\d{10}$/.test(cleanedPhoneNumber)) {
      alert("Invalid phone number format");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: cleanedPhoneNumber }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send OTP");
      }

      alert("OTP sent to your phone number successfully");
      navigate("/otp-screen", { state: { phoneNumber: cleanedPhoneNumber } });
    } catch (error) {
      console.error("Failed to send OTP:", error.message);
      alert("Failed to send OTP", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyPress = ({ nativeEvent: { key: keyValue } }, index) => {
    if (keyValue === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const submitOTP = async () => {
    const enteredOtp = otp.join("");

    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber,
          otp: enteredOtp,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify OTP");
      }

      const data = await response.json();
      if (data.token) {
        const { uid, profileSetupRequired, token, phoneNumber } = data;
        localStorage.setItem("userToken", token); // Store token
        localStorage.setItem("phoneNumber", phoneNumber); // Store token
        localStorage.setItem("userId", uid); // Store token

        // alert("Phone number verified successfully!");

        setTimeout(() => {
          if (profileSetupRequired) {

            navigate("/profile-setup", { state: { phoneNumber, uid } });
          } else {
            navigate("/"); // Navigate to HomePage or any other screen
          }
        }, 0);
      } else {
        alert("Verification failed. Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Verification error:", error.message);
      alert("Verification failed", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (<>
  <div className="abs-ack bg-clr">
          <button className="back-button" onClick={() => navigate(-1)}>
            <img src={backlogo} alt="Back" className="icon" />
          </button>
          <div className="logo-div">
            <Link to="/">
              <img className="logo-size" src={logo} alt="logo" />
            </Link>
          </div>
        </div>
    <div className="verify-otp-page">
      <div className="background-image">
        
        <div className="content">
          <h1 className="title">Log in </h1>
          <p>We have sent you a verification by Phone</p>
          <h3 className="subtitle">{phoneNumber}</h3>

          <div className="otp-input">
            {otp.map((value, index) => (
              <input
                key={index}
                ref={inputRefs[index]}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, index)}
                className="otp-input-field"
              />
            ))}
          </div>
          <p onClick={handleSendOtp}>Resend OTP</p>

          <button
            className={`login-button ${loading ? "disabled" : ""}`}
            onClick={submitOTP}
            disabled={loading}
          >
            {loading ? (
              <span className="loader"></span>
            ) : (
              <span className="button-text">Verify OTP</span>
            )}
          </button>
        </div>
      </div>
    </div>
  </>);
}