import React, { useState, useEffect } from "react";
import "./css/Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import { Link } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import logo from "../images/logo superwin.png";
import BASE_URL from "./config/config";
import menuimg from "../images/menu_burger.png";

const avatars = {
  avatar_1: require("../assets/avatar/avatar_1.png"),
  avatar_2: require("../assets/avatar/avatar_2.png"),
  avatar_3: require("../assets/avatar/avatar_3.png"),
  avatar_4: require("../assets/avatar/avatar_4.png"),
  avatar_5: require("../assets/avatar/upload_avatar.png"),
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showLinks, setShowLinks] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const fetchWalletBalance = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
//alert("Error: You must be logged in to perform this action.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/userdata`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setWalletBalance(data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    //  alert("Error: Failed to fetch wallet balance.");
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    setIsLoggedIn(!!token);
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserData(decoded);
        // console.log("Decoded user data:", decoded);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [navigate]);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
    if (!showLinks) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  const closeMenu = () => {
    setShowLinks(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    const fetchWalletBalance = async () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        try {
          const response = await fetch(`${BASE_URL}api/userdata`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          // console.log("Fetched wallet balance data:", data);
          if (data && typeof data.walletBalance === 'number') {
            setWalletBalance(data.walletBalance);
          } else {
            console.error("Invalid wallet balance data:", data);
            setWalletBalance(0);
          }
        } catch (error) {
          console.error("Error fetching wallet balance:", error);
          setWalletBalance(0);
        }
      }
    };
    fetchWalletBalance();
    const intervalId = setInterval(fetchWalletBalance, 50000);

    return () => clearInterval(intervalId); 
  }, []);

  const logoutUser = async () => {
    try {
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  return (
    <div className={`main-header ${scrolled ? "scrolled" : ""}`}>
      <div className="logo-div">
        <Link to="/">
          <img onClick={closeMenu} className="logo-size" src={logo} alt="logo" />
        </Link>
      </div>
      <IoMenu
        className={`hamburger-menu ${showLinks ? "hide" : ""}`}
        onClick={toggleLinks}
      />
      <div className={`links ${showLinks ? "show" : ""}`}>
        <IoClose className="close-button" onClick={closeMenu} />
        <Link className="a-style" onClick={closeMenu} to="/faq">FAQ'S</Link>
        <Link  className="a-style" onClick={closeMenu} to="/support">Apps & GAmes</Link>
        <Link  className="a-style" onClick={closeMenu} to="/about">ABOUT</Link>

        {isLoggedIn ? (
          <div className="avatar-home">
            <Link  onClick={closeMenu} to="/profile">
              <img className="avatarr" src={avatars[userData.avatar]} alt="Avatar" />
              {location.pathname !== "/profile" && <img className="burger" src={menuimg} alt="Menu" />}
            </Link>
           
            <div className="hover-div">
              <div className="hover-box-1">
                <p>{userData.name}</p>
                <p>
                  Balance:{" "}
                  {walletBalance !== null ? walletBalance.toFixed(2) : "0.00"}
                </p>
                <p>userID: {userData.userId}</p>
              </div>
              <div className="hover-box-2">
                <Link  onClick={closeMenu} to="/profile">View profile</Link>
                <Link  onClick={closeMenu} to="/deposit">Add Money</Link>
                <Link  onClick={closeMenu} to="/withdraw">Withdraw</Link>
                <Link  onClick={closeMenu} to="/rooms">Rooms</Link>
                <button onClick={logoutUser}>Logout</button>
              </div>
            </div>
          </div>
        ) : (
          <Link  onClick={closeMenu} to="/prelogin"><div className="new-signin">SIGN IN</div></Link>
        )}
      </div>
      {showLinks && <IoClose className="close-menu" onClick={toggleLinks} />}
    </div>
  );
};

export default Header;