import React, { useState } from 'react';
import './Home.css';
import Header from '../components/Header.js';
import star from '../images/Star.png';
import Slider from '../components/Slider';
import img from '../images/Artboard.png';
import avtar from '../images/club-girl.png';
import { games } from '../data/Gamedata.js';
import Carousel from '../components/Review.js';
import Footer from '../components/Footer.js';
import Carousel2 from '../components/Slider2.js';
import big from '../images/Artboard_prev_ui.png';
import Marquee from 'react-fast-marquee';
import CarouselComponent from '../components/Slider3.js';

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const printdata = games.map((item) => {
    return (
      <div className="main-array">
        <div className="array-img">
          <img src={item.img} alt="game image" />
        </div>
        <p className="array-heading">{item.heading}</p>
        <div className="player-div">
          <img src={item.playerimg} />
          <div className="player-detail">
            <p className="player-name">{item.playername}</p>
            <p className="game-name">{item.gamename}</p>
          </div>
        </div>
        <button className="livedemo">{item.button}</button>
      </div>
    );
  });
  return (
    <>
      <div className="super-main">
        <div className="mainnn">
          <Header />
          <div className="home-main">
            <div className="head"></div>
            <div className="home-content">
              <div className="texts">
                <h1>
                  Let your mind<h1 className="color">explore</h1> new world
                </h1>
                <p>Playing electronic games, whether through consoles, computers, mobile phones or another medium altogether. Gaming is a nuanced term that suggests regular gameplay, possibly as a hobby.</p>
                <div className="button-divv">
                  <a href="/app-release.apk" download>
                    <button className="btn1">DOWNLOAD</button>
                  </a>
                  <button className="btn2">
                    <span className="btn2-gre">PLAY NOW</span>
                  </button>
                </div>
                <div className="happy-customer">
                  <div className="counter1">
                    <h2>300+</h2>
                    <p>Unique style</p>
                  </div>
                  <div className="counter1">
                    <h2 className="color">300+</h2>
                    <p>Project finished</p>
                  </div>
                  <div className="counter1">
                    <h2>300+</h2>
                    <p>Happy customer</p>
                  </div>
                </div>
              </div>
              {/* <div className="big-image">
                        <img src={big}/>
                    </div> */}
            </div>
          </div>
          <div className="marquee-container">
            <Marquee speed={200} className="marquee-content" pauseOnHover={true} direction="right">
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>Action - packed</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>mind - bending</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>collection of games</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>Gaming spanning</h2>
              </div>
              {/* Repeat the items to ensure smooth scrolling */}
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>Action - packed</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>mind - bending</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>collection of games</h2>
              </div>
              <div className="marquee-item">
                <img src={star} alt="star" /> <h2>Gaming spanning</h2>
              </div>
            </Marquee>
          </div>
        </div>

        <div className="main2">
          <div className="main2-heanding">
            <h1>
              choose your <h1 className="colorr">favorite </h1>games
            </h1>
            <p>Offer sneak peeks and previews of upcoming games, including trailers, screenshots, and information about release.</p>
          </div>
          <Carousel2 />
          <div className="button-divv">
            <button className="btn1">VIEW ALL</button>
            <button className="btn2">
              <span className="btn2-gre">PLAY NOW</span>
            </button>
          </div>
        </div>

        <div className="supermain3">
          <div className="main3">
            <div className="main3-div">
              <div className="main3-img">
                <img src={avtar} />
              </div>
              <div className="main3-content">
                <h2>
                  Discover the <h2 className="color">Virtual</h2> Reality Gamin{' '}
                </h2>
                <p>A well-designed gaming header often incorporates elements such as game characters, iconic symbols, vibrant colors, and dynamic visuals to convey excitement, adventure, and the immersive nature of gaming.</p>
                <button className="btn2 adj-width ">
                  <span className="color">PLAY NOW</span>
                </button>
              </div>
            </div>
          </div>
          <Marquee autoFill={true} speed={150} className="marquee2" direction="right" pauseOnHover={true}>
            <div className="star">
              <img src={star} /> <h2>Action - packed</h2>{' '}
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>mind - bending</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>collection og games</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
          </Marquee>
        </div>

        <div className="supermain4">
          <div className="main4">
            <div className="main4-heading">
              <h1>Welcome to the top games</h1>
            </div>
            <div className="btn-div">
              {[...Array(4)].map((_, index) => (
                <button key={index} className={`add2 new-btn2  ${activeIndex === index ? 'activeie' : ''}`} onClick={() => handleButtonClick(index)}>
                  <span className="btn2-gr">Newest Games</span>
                </button>
              ))}
            </div>
            <div className="print-data">{printdata}</div>
          </div>
          <Marquee autoFill={true} speed={150} className="marquee2" direction="right">
            <div className="star">
              <img src={star} /> <h2>Action - packed</h2>{' '}
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>mind - bending</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>collection og games</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
            <div className="star">
              {' '}
              <img src={star} /> <h2>Gaming spaning</h2>
            </div>
          </Marquee>
        </div>

        <div className="main5">
          <Carousel />
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Home;
