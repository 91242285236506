import React, { useState, useEffect } from "react";
import './css/Slider2.css';
import game1 from '../images/array-img2.png';
import vector from '../images/array-img3.png';
import game2 from '../images/array-img2.png';
import game3 from '../images/array-img3.png';
import game4 from '../images/array-img3.png';
import game5 from '../images/array-img2.png';
import frame from '../images/Design.png'; // Import the frame image

const Carousel2 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const data = [
        { id: 1, content: "Item 1", img: game1 },
        { id: 2, content: "Item 2", img: vector },
        { id: 3, content: "Item 3", img: game2 },
        { id: 4, content: "Item 4", img: game3 },
        { id: 5, content: "Item 5", img: game4 },
        { id: 6, content: "Item 6", img: game5 }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
        }, 3000); // Adjust the interval time as needed

        return () => clearInterval(interval);
    }, [data.length]);

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper" style={{ transform: `translateX(calc(-${currentIndex * (100 / data.length)}% + (50% - 100px)))` }}>
                {data.map((item, index) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'activee' : ''}`}>
                        <div className="under-div">
                        <img className="img-silder" src={item.img} alt={item.content} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="carousel-frame">
                <img className="frame" src={frame} alt="frame" />
            </div>
        </div>
    );
}

export default Carousel2;