
//export const PORT = process.env.PORT || 3000;
//export const MONGODB_URI = 'mongodb+srv://kanimesh407:Sushant123@cluster0.osukhvt.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0'
// export const JWT_SECRET = ' 51b380220db94166d4c6056f9ebd36e14d2ff71bfbdc4a29dba7c299c0ed6ad7';

// src/config.js
// const BASE_URL = 'http://192.168.1.14:5500/';
// const BASE_URL = 'https://superwinback-2.onrender.com/';
const BASE_URL = 'https://superwin-app-server.onrender.com/';

export default BASE_URL;
