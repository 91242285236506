import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./config/config";   
import "./css/Addmoney.css";

const AddMoneyScreen = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(null);

  const quickAmounts = [100, 500, 1000, 2000, 5000];

  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const fetchWalletBalance = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      window.alert("Error: You must be logged in to perform this action.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/userdata`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setWalletBalance(data.walletBalance);
   
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      window.alert("Error: Failed to fetch wallet balance.");
    }
  };

  const handleAddMoney = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      window.alert("Error: You must be logged in to perform this action.");
      return;
    }

    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      window.alert("Invalid Input: Please enter a valid amount.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}api/add_money`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount: numericAmount }),
      });
      const data = await response.json();
      window.alert("Success: Money added successfully!");
      setAmount("");
      setWalletBalance(data.walletBalance);
    } catch (error) {
      console.error("Error adding money:", error);
      window.alert("Error: Failed to add money.");
    } finally {
      setLoading(false);
    }
  };

  const fillAmount = (value) => {
    setAmount((prevAmount) => {
      const numericPrevAmount = parseFloat(prevAmount) || 0;
      return (numericPrevAmount + value).toString();
    });
  };

  return (
    <div className="mainView">
      <div className="backgroundStyle"></div>
      <div className="safeArea">
        <div className="headerContainer">
          <h1 className="header">Add Money</h1>
        </div>
        <img
          src={require("../assets/Line.png")}
          alt="line"
          className="lineImage"
        />
        <div className="balanceContainer">
          <p className="balanceText">
          Balance:{" "}
  {typeof walletBalance === "number"
    ? walletBalance.toFixed(2)
    : "0.00"}
          </p>
        </div>
        <div className="inputContainer">
          <img src={require("../assets/coin.png")} alt="coin" className="icon" />
          <input
            className="input"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
          />
        </div>
        <p className="quickText">Quick Select</p>
        <div className="quickFillRow">
          {quickAmounts.map((item) => (
            <button
              key={item}
              className="quickFillButton"
              onClick={() => fillAmount(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          className={`loginButton ${isLoading ? "disabledButton" : ""}`}
          onClick={handleAddMoney}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            "Add Money"
          )}
        </button>
        <div className="linkcont">
          <button onClick={() => navigate("/profile")}>Back to Profile</button>
        </div>
      </div>
    </div>
  );
};

export default AddMoneyScreen;
