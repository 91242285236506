// SendOtpPage.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL from "./config/config";
import "./css/SendOtpPage.css";
import phone from "../images/phone.png"
import logo from "../images/logo superwin.png";
import backlogo from "../images/backlogo.png"

export default function SendOtpPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (!/^(\+?\d{1,3})?\d{10}$/.test(cleanedPhoneNumber)) {
      alert("Invalid phone number format");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: cleanedPhoneNumber }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send OTP");
      }

      alert("OTP sent to your phone number successfully");
      navigate("/otp-screen", { state: { phoneNumber: cleanedPhoneNumber } });
    } catch (error) {
      console.error("Failed to send OTP:", error.message);
      alert("Failed to send OTP", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (<>
 <div className="abs-ack bg-clr">
      <button className="back-button" onClick={() => navigate(-1)}>
        <img src={backlogo} alt="Back" className="icon" />
      </button>
      <div className="logo-div">
        <Link to="/">
          <img className="logo-size" src={logo} alt="logo" />
        </Link>
      </div>
    </div>
    <div className="send-otp-page">
      <div className="background-image">
       
        <div className="content">
          <img className="logo-size" src={logo} alt="logo" />
          <h1 className="title">Create Account</h1>

          <div className="input-container">
            <img src={phone} alt="Phone" className="icon" />
            <input
              type="text"
              placeholder="91 xxxxxxxxxx"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="input"
            />
          </div>
          <button
            className={`login-button ${loading ? "disabled" : ""}`}
            onClick={handleSendOtp}
            disabled={loading}
          >
            {loading ? (
              <span className="loader"></span>
            ) : (
              <span className="button-text">Send OTP</span>
            )}
          </button>
        </div>
      </div>
    </div>
    </>);
}