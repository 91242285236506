import {
    FaChevronDown,
    FaChevronUp,
    FaCreditCard,
    FaWallet,
    FaBank,
    FaClock,
    FaMoneyCheckAlt,
    FaGamepad,
    FaUserShield,
    FaQuestionCircle,
    FaRegIdBadge,
    FaEnvelopeOpenText,
  } from "react-icons/fa";
  import { RiBankFill } from "react-icons/ri";
  
export const faqs = [
    {
      question: "How do I make a deposit?",
      answer:
        "To make a deposit, log into your SUPERWIN account and go to the Profile section - Deposit. Select your preferred payment method and enter the amount you wish to deposit. The funds will be credited to your account instantly.",
     n1: <FaCreditCard />,
    },
    {
      question: "What payment methods are accepted?",
      answer:
        "SUPERWIN accepts a variety of payment methods including credit/debit cards, e-wallets, bank transfers, and a range of UPI enabled apps. The specific methods available may vary by country.",
     n1: <FaWallet />,
    },
    {
      question: "Is there a minimum or maximum deposit amount?",
      answer:
        "Yes, there are limits on deposits. The minimum deposit is 500 INR and the maximum is 1,00,000 INR per transaction. Deposit limits may be lower for certain payment methods.",
     n1: <RiBankFill />,
    },
    {
      question: "How long do withdrawals take?",
      answer:
        "Withdrawal processing times vary depending on the payment method. E-wallets are typically the fastest, with withdrawals processed within 1 hour.",
     n1: <FaClock />,
    },
    {
      question: "Is there a fee for withdrawals?",
      answer:
        "SUPERWIN does not charge any fees for withdrawals. However, some payment providers may apply their own fees. Check with your payment provider for details.",
     n1: <FaMoneyCheckAlt />,
    },
    {
      question: "Is SUPERWIN licensed and regulated?",
      answer:
        "Yes, SUPERWIN is licensed and regulated by concerned authorities in each country. We adhere to strict standards for fairness, security and responsible gaming.",
     n1: <FaRegIdBadge />,
    },
    {
      question: "How do I know the games are fair?",
      answer:
        "All games on SUPERWIN use a random number generator (RNG) that is regularly tested and certified by independent third parties to ensure fairness.",
     n1: <FaGamepad />,
    },
    {
      question: "What is the minimum age to play?",
      answer:
        "The minimum age to play on SUPERWIN is 18. We take underage gambling very seriously and have measures in place to prevent minors from accessing our platform.",
     n1: <FaUserShield />,
    },
    {
      question: "What if I have a problem with my account or a game?",
      answer:
        "If you encounter any issues, please contact our customer support team via live chat or email. We have a dedicated team available 24/7 to assist you.",
     n1: <FaEnvelopeOpenText />,
    }
  ];