import React, { useEffect, useState } from "react";
import Buttons from "./ButtonGroup";
import "./css/Profile2.css"
import Header from "./Header";
import Footer from "./Footer";
import FaQ from "./Faq";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import BASE_URL from "./config/config";
import Profile from "./Profilescreen"
import Account from "./userdetail/Account";
import Wallet from "./userdetail/Wallet";
import i1 from "../images/I1.png"
import i2 from "../images/I2.png"
import i3 from "../images/I3.png"
import line3 from "../images/Line 3.png"
import Support from "./userdetail/Support";
import Settings from "./userdetail/Settings";
const Profile2 = () => {
  const [userData, setUserData] = useState(null);
  const [walletBalance, setWalletBalance] = useState("0");
  const navigate = useNavigate();

  const avatars = {
    avatar_1: require("../assets/avatar/avatar_1.png"),
    avatar_2: require("../assets/avatar/avatar_2.png"),
    avatar_3: require("../assets/avatar/avatar_3.png"),
    avatar_4: require("../assets/avatar/avatar_4.png"),
    avatar_5: require("../assets/avatar/avatar_5.png"),
    uploadAvatar: require("../assets/avatar/upload_avatar.png"),
  };



  useEffect(() => {
    const fetchData = async () => {

      const logoutUser = async () => {
        try {
          localStorage.clear();
          console.log("User logged out successfully");
          navigate("/");
        } catch (error) {
          console.error("Failed to log out:", error);
        }
      };

      try {
        const token = localStorage.getItem("userToken");
        if (token) {
          const decoded = jwtDecode(token);
          console.log("Decoded JWT:", decoded);
          setUserData(decoded);
        } else {
          console.log("No token found, redirecting to home screen");
          navigate("/");
        }
      } catch (error) {
        console.error("Error retrieving or decoding token:", error);
      }
    };
    fetchData();
  }, [navigate]);

  const logoutUser = async () => {
    try {
      localStorage.clear();
      console.log("User logged out successfully");
      navigate("/");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };


  useEffect(() => {
    const fetchWalletBalance = async () => {
      const token = localStorage.getItem("userToken");
      if (!token) {
        alert("Error: You must be logged in to perform this action.");
        return
      }

      try {
        const response = await fetch(`${BASE_URL}api/userdata`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setWalletBalance(data.walletBalance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
        window.alert("Error: Failed to fetch wallet balance.");
      }
    };
    fetchWalletBalance();
  }, [])









  const [isselected, setselected] = useState(0)
  const btns = [
    {
      img: i1,
      name: "My Profile"
    },
    {
      img: i2,
      name: "Wallet",
    },
    {
      img: i2,
      name: "Settings"
    },
    {
      img: i3,
      name: "Supports"
    }
  ]

  const RenderComponent = ({ index }) => {
    switch (index) {
      case 0: return <Account />;
        break;
      case 1: return <Wallet />;
        break;
      case 2: return <Settings/>;
        break;
        case 3:return <Support/>;
        break;
    }
  }
  return (<>
    <div className="main-pro">
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="flex-wrapper">
        <div className="button-contain-1">
          <h1>MY ACCOUNT</h1>
          <Buttons btn={btns} isselected={isselected} setselected={setselected} />
          <img className="line" src={line3} />
          <button className=" log" onClick={logoutUser}>Logout</button>
          
  
          </div>
   
        <div ><RenderComponent className="comp" index={isselected} /></div>
      </div>
      <Footer />
    </div>
  </>);

}
export default Profile2