import React from "react";
import './css/Footer.css'
import headerlogo from '../images/headerlogo.png'
import { Link } from "react-router-dom";
import a1 from '../images/a1.png'
import a2 from '../images/a2.png'
import a3 from '../images/a3.png'
import a4 from '../images/a4.png'
import a5 from '../images/a5.png'
import { FaTwitter } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { SlSocialInstagram } from "react-icons/sl";
import { FaGithub } from "react-icons/fa";
import logo from "../images/logo superwin.png"
import star from '../images/Star.png'
import Marquee from "react-fast-marquee";


const Footer = () => {
    return (<>
    <div className="marquee-container2">
                    <Marquee speed={200} className="marquee-content"
                        pauseOnHover={true} direction="left">
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                        {/* Repeat the items to ensure smooth scrolling */}
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                    </Marquee>
                </div>
        <div className="main-footer">
            <div className="foo">
            <div className="footer-div1">
                <div className="logo-container">

                <div className="logo-div">
             <Link to="/"  > <img className="logo-size" src={logo} alt="logo" /></Link>
            </div>
            <div className="footer-div-2">
                    <p>A well-designed gaming header often incorporates
                        elements such as game characters, iconic symbols,<br/>s vibrant colors,
                        and dynamic visuals .</p>
                        
                <div className="detail1">
                    <h3>company</h3>
                    <div className="links-div">
                    <Link to="">PRODUCTS</Link>
                    <Link to="">APPS & GAMES</Link>
                    <Link to="">FEATURES</Link>
                
                    </div>

                </div>
                        </div>
                </div>

               
            </div>
            
            
            </div>
            <div className="footer-div3">
                <div className="social-handles">    
                <div className="circle"><FaTwitter  className="iconn"/></div>
                <div className="circle2"><RiFacebookFill className="iconn2"/></div>
                <div className="circle"><SlSocialInstagram className="iconn"/></div>
                <div className="circle"><FaGithub   className="iconn"/></div>
                </div>
                <p className="">© Copyright 2023, All Rights Reserved by Pixel Bharat</p>
            </div>
        </div>
        
    </>)

}
export default Footer