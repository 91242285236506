import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./config/config";
import "./css/Rooms.css";
import Header from "./Header";
import Footer from "./Footer";
import line from "../assets/Line4.png";
import Createroompopup from "./Createroompopup";
import Joinroompopup from "./Joinroompopup";

export default function RoomScreen() {
  const navigate = useNavigate();
  const [recentRooms, setRecentRooms] = useState([]);
  const [showCreateRoomOverlay, setShowCreateRoomOverlay] = useState(false);
  const [showJoinRoomOverlay, setShowJoinRoomOverlay] = useState(false);

  useEffect(() => {
    fetchRecentRooms();
  }, []);

  const fetchRecentRooms = async () => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${BASE_URL}admin-rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response)
      if (!response.ok) {
        throw new Error("Failed to fetch recent rooms");
      }
      const data = await response.json();
      setRecentRooms(data);
      console.log("Recent rooms:", data);
    } catch (error) {
      console.error("Error fetching recent rooms:", error);
    }
  };

  const renderItem = (item) => {
    if (item.role === "Admin") {
      return (
        <div className="roomCard" key={item.roomID}>
          <div>
            <h3 className="roomName">{item.roomName}</h3>
            <p className="roomDetails">Room ID: {item.roomID}</p>
            <p className="roomDetails">Role: {item.role}</p>
            <div className="member_id">
              <p className="mem_cnt">
                Members: <span className="mem_count">{item.membercount}</span>
              </p>
            </div>
          </div>
          <button className="joinButton" onClick={() => joinRoom(item)}>
            Join
          </button>
        </div>
      );
    } else {
      return null; // Return null if role is not "admin"
    }
  };

  const renderItem2 = (item) => {
    if (item.role === "Members") {
      return (
        <div className="roomCard" key={item.roomID}>
          <div>
            <h3 className="roomName">{item.roomName}</h3>
            <p className="roomDetails">Room ID: {item.roomID}</p>
            <p className="roomDetails">Role: {item.role}</p>
            <div className="member_id">
              <p className="mem_cnt">
                Members: <span className="mem_count">{item.membercount}</span>
              </p>
            </div>
          </div>
          <button className="joinButton" onClick={() => joinRoom(item)}>
            Join
          </button>
        </div>
      );
    } else {
      return null; // Return null if role is not "admin"
    }
  };


  const joinRoom = (room) => {
    alert("Joining room:", room);
    console.log("room details", room);
    navigate("/adminroom", { state: {room:room } }); // Navigate to Adminroom component with room details
  };

  return (
    <>
      <Header />

      <div className="send-otp-page">
        <div className="background-imagee">
          <div className="containerroom">
            <div className="buttonContainer">
              <button
                onClick={() => setShowCreateRoomOverlay(true)}
                className="create-room-btn"
              >
                + CREATE A ROOM
              </button>
              <p>or</p> 
              <br />
              <button
                onClick={() => setShowJoinRoomOverlay(true)}
                className="join-room-btn"
              >
                JOIN BY ROOM ID
              </button>
            </div>

            <img className="line-size" src={line} alt="line" />

            <div className="recentRooms">
              <h1 className="recenthead">Recent Rooms</h1>
              <div className="recentRoomsList">
                <div className="admin-rooms-display">
              <h1 className="recenthead">Admin Rooms</h1>
                {recentRooms.map((item) => renderItem(item))}
                </div>

                <div className="admin-rooms-display">
              <h1 className="recenthead">Members Room</h1>
                {recentRooms.map((item) => renderItem2(item))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCreateRoomOverlay && (
        <Createroompopup onClose={() => setShowCreateRoomOverlay(false)} />
      )}

      {showJoinRoomOverlay && (
        <Joinroompopup onClose={() => setShowJoinRoomOverlay(false)} />
      )}

      <Footer />
    </>
  );
}
