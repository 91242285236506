export const aboutdata=[
    {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    },
    {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    },
    {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    },
    {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    }, {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    },
    {
        img:"null",
        name:"core philosophies",
        para:"hy there"

    },

]