import React, { useEffect, useState } from "react";
import "./css/Settings.css";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import BASE_URL from "../config/config";
import coin from "../../images/coin.png";
import grey from "../../images/grey.png";
import gold from "../../images/gold.png";
import sr from "../../images/sr.png";
import line3 from "../../images/Line 3.png";
import acline from "../../images/acline.png";
import pen from "../../assets/pencil.svg";

const Settings = () => {
    const [userData, setUserData] = useState(null);
    const [walletBalance, setWalletBalance] = useState(0);
    const [showCard, setShowCard] = useState(false);
    const [pushNotification, setPushNotification] = useState(false); 
    const [inputNotification, setInputNotification] = useState(false); 
    const [selectedLanguage, setSelectedLanguage] = useState("Hindi"); 

    const navigate = useNavigate();
    const avatars = {
        avatar_1: require("../../assets/avatar/avatar_1.png"),
        avatar_2: require("../../assets/avatar/avatar_2.png"),
        avatar_3: require("../../assets/avatar/avatar_3.png"),
        avatar_4: require("../../assets/avatar/avatar_4.png"),
        avatar_5: require("../../assets/avatar/upload_avatar.png"),
    };

    const nav = () => {
        navigate('/profile-setup');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("userToken");
                if (token) {
                    const decoded = jwtDecode(token);
                    console.log("Decoded JWT:", decoded);
                    setUserData(decoded);
                    setShowCard(true);  // Show the card when user data is set
                } else {
                    console.log("No token found, redirecting to home screen");
                    navigate("/");
                }
            } catch (error) {
                console.error("Error retrieving or decoding token:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            const token = localStorage.getItem("userToken");
            if (!token) {
                window.alert("Error: You must be logged in to perform this action.");
                return;
            }

            try {
                const response = await fetch(`${BASE_URL}api/userdata`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setWalletBalance(data.walletBalance);
            } catch (error) {
                console.error("Error fetching wallet balance:", error);
                window.alert("Error: Failed to fetch wallet balance.");
            }
        };
        fetchWalletBalance();
    }, []);

    const handlePushNotificationChange = (e) => {
        setPushNotification(e.target.checked);
    };

    const handleInputNotificationChange = (e) => {
        setInputNotification(e.target.checked);
    };

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className={`main-card-div ${showCard ? "show" : ""}`}>
                <img src={acline} alt="acline" />
                <div className="card-details card-width">
                    <div className="account-img flex-just">
                        <div className="acc-details flex">
                            <div className="img-name">
                                <img className="avatar" src={avatars[userData.avatar]} alt="Avatar" />
                            </div>
                            <div>
                                <div className="coin">
                                    <img src={coin} alt="coin" />
                                    <h2>{userData.name}</h2>
                                </div>
                                <div className="wr">
                                    <p className="grey">UID {userData.userId}</p>
                                    <img src={grey} alt="grey" />
                                </div>
                            </div>
                        </div>
                        <img onClick={nav} src={pen} alt="Edit profile" />
                    </div>
                    <img className="vertical" src={line3} alt="line" />
                    <div className="input-card-contain">
                        <div className="input-card">
                            <p>Push Notification</p>
                            <input
                                type="checkbox"
                                checked={pushNotification}
                                onChange={handlePushNotificationChange}
                            />
                        </div>
                        <div className="input-card">
                            <p>Input Notification</p>
                            <input
                                type="checkbox"
                                checked={inputNotification}
                                onChange={handleInputNotificationChange}
                            />
                        </div>
                        <div className="input-card">
                            <p>Language</p>
                            <select value={selectedLanguage} onChange={handleLanguageChange}>
                                <option value="Hindi">Hindi</option>
                                <option value="English">English</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Settings;
