import react from "react";
import backlogo from "../images/backlogo.png"
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo superwin.png";
const Letsgo = () => {
  const navigate = useNavigate();
  const gostart = () => {
    navigate("/login")
  }
  return (<>
  <div className="abs-ack bg-clr">
        <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backlogo} alt="Back" className="icon" />
        </button>
        <div className="logo-div">
          <Link to="/">
            <img className="logo-size" src={logo} alt="logo" />
          </Link>
        </div>
      </div>
    <div className="send-otp-page">
      
      <div className="background-image">

        <div className="content">
          <img className="logo-size" src={logo} alt="logo" />
          <h1 className="title"><span>Generate</span><span> anything what’s in your</span><span> mind now</span></h1>
          <p>An AI that developed to help you generate what’s in your mind into beautiful visual</p>

          <button onClick={gostart} className="login-button">Lets Go
          </button>
          <span className="margin"><Link to="/">Back to Home</Link></span>
        </div>
      </div>
    </div>
  </>)
}
export default Letsgo