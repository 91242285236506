import React, { useState, useEffect } from 'react';
import './css/Review.css'
import ratingimg from '../images/rating.png'
import line from '../images/Line 2.png'
import people from "../images/people.png"
import verfy from '../images/verify.png'
import avtar from'../images/Vector.png'
import extraimage from '../images/extraimage.png'

const Carousel = () => {
  const items = [
    {  ratingimg:ratingimg,
        content:"One of the standout features of this gaming website is its extensive library of game guides and tutorials. It has helped me level up my skills, conquer challenging quests, and discover hidden secrets within games. The guides are comprehensive, easy to follow, and have undoubtedly elevated my gaming performance.",
        line:line,
        peopleimg:people,
        peoplename:"Arlene ",
        peoplefrom:"McDonald's",
        verify:verfy
     },
     {  ratingimg:ratingimg,
        content:"One of the standout features of this gaming website is its extensive library of game guides and tutorials. It has helped me level up my skills, conquer challenging quests, and discover hidden secrets within games. The guides are comprehensive, easy to follow, and have undoubtedly elevated my gaming performance.",
        line:line,
        peopleimg:avtar,
        peoplename:"Arlen",
        peoplefrom:"mosco",
        verify:verfy
     }, {  ratingimg:ratingimg,
        content:"One of the standout features of this gaming website is its extensive library of game guides and tutorials. It has helped me level up my skills, conquer challenging quests, and discover hidden secrets within games. The guides are comprehensive, easy to follow, and have undoubtedly elevated my gaming performance.",
        line:line,
        peopleimg:people,
        peoplename:"Coy",
        peoplefrom:"seria",
        verify:verfy
     }, {  ratingimg:ratingimg,
        content:"One of the standout features of this gaming website is its extensive library of game guides and tutorials. It has helped me level up my skills, conquer challenging quests, and discover hidden secrets within games. The guides are comprehensive, easy to follow, and have undoubtedly elevated my gaming performance.",
        line:line,
        peopleimg:people,
        peoplename:"Arlene McCoy",
        peoplefrom:"cedny",
        verify:verfy
     },
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 2) % items.length);
    }, 3000); // Change items every 3 seconds

    return () => clearInterval(interval);
  }, [items.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const currentItems = items.slice(currentIndex, currentIndex + 2);

  return (
    <div className='super-main-sider'>
      <div className='main-sider-div' >
        {currentItems.map((item, index) => (
          <div className='item-div' >
            
            <div className='review-img'><img src={item.ratingimg}/></div>
            <p className='contentt'>{item.content}</p>
            <div className='lineimg'>
            <img src={item.line}/>
            </div>
            <div className='people-detail'>
                <div className='wraper'>
                <div className='people-img'><img src={item.peopleimg}/></div>
                <div className='people-content'><p className='people-name'>{item.peoplename}</p>
                <p className='people-from'>{item.peoplefrom}</p></div>
                </div>
                <div className='verify-img'><img src={item.verify}/><p className='verify'>varified</p></div>
            </div>
    
          </div>
        ))}
      </div>
      <div className="carousel-container">
        {Array(Math.ceil(items.length / 2)).fill().map((_, index) => (
          <span
            key={index}
            onClick={() => handleDotClick(index * 2)}
            className={`carousel-dot ${currentIndex === index * 2 ? 'active' : 'inactive'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;