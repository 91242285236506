import React, { useEffect, useState } from "react";
import x from "../images/arrow-left-line.png"
import person from "../images/person.png"
import BASE_URL from "./config/config";
import { useNavigate } from "react-router-dom";
import { IonIcon } from '@ionic/react';
const Joinroompopup = ({ onClose }) => {
    const [roomID, setRoomID] = useState('');
    const [isChecked, setChecked] = useState(false);
    const [userData, setUserData] = useState(null);
    const [token, setToken] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const toggleCheckbox = () => {
        setChecked(!isChecked);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('userToken');
                if (token) {
                    setToken(token);
                    const response = await fetch(`${BASE_URL}api/userdata`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }
                    const data = await response.json();
                    setUserData(data);
                } else {
                    console.log('Token not found');
                }
            } catch (error) {
                console.error('Error retrieving user data:', error);
            }
        };
        fetchData();
    }, []);

    const joinRoom = async () => {
        try {
            if (!isChecked) {
                throw new Error('Please agree to the Terms & Conditions');
            }
            const response = await fetch(`${BASE_URL}join-room`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ iD: roomID }),
            });
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Failed to join room: ${errorMessage}`);
            }
            const responseData = await response.json();
            console.log('Joined room successfully:', responseData);
            navigate('/room-user');
        } catch (error) {
            console.error('Error joining room:', error);
        }
    };

    return (<>
        <div className="overlay">
            <div className="overlay-content">
                <div className="head-addmoney">
                    <h2>Join Room</h2>
                    <img src={x} onClick={onClose} />
                </div>
{/* 
                    <button className="backButton" onClick={() => navigate(-1)}>
                        <IonIcon icon="arrow-back" size="large" color="#fff" />
                    </button> */}

                    <div className="inputContainer new-size">
                        <div className="input ">
                            <p className="lvl-pop ">
                                {userData ? userData.uid : 'Loading...'}
                            </p>
                        </div>
                    </div>
                    <div>
                    <label className="lvl-pop">Room ID</label>
                    <div className="inputContainer new-size">
                
                        <input
                            className="input"
                            placeholder="Enter Room id"
                            value={roomID}
                            onChange={(e) => setRoomID(e.target.value)}
                        />
                    </div>
                    </div>
                    <div className="checkboxContainer" onClick={toggleCheckbox}>
                    <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) =>  setChecked(e.target.checked)}
                className="term-input"
              />
                        <p className="checkboxLabel"><span className="stl-spn">I agree to the</span><span className="lvl-pop"> Terms & Conditions</span></p>
                    </div>
                    <button
                        className={`loginButton ${isLoading ? "disabledButton" : ""}`}
                        onClick={joinRoom}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="spinner"></div>
                        ) : (
                            "Create room "
                        )}
                    </button>
                


            </div>
        </div>
    </>)
}
export default Joinroompopup