import React, { useState } from "react";
import "./css/Faq.css";
import Header from "./Header";
import Footer from "./Footer";
import Marquee from "react-fast-marquee";
import star from '../images/Star.png'
import imgmarquee from'../images/marquee-img.png'
import { faqs } from "../data/Faqdata";
import {
  FaChevronDown,
  FaChevronUp,
  FaCreditCard,
  FaWallet,
  FaBank,
  FaClock,
  FaMoneyCheckAlt,
  FaGamepad,
  FaUserShield,
  FaQuestionCircle,
  FaRegIdBadge,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  
 
  return (
    <>
      <div className="new-super-div">
        <Header />
        <br />
        <br />
        <br />
        <br />
        <br />
        < div className="faq-heading"><h1>FAQ’s</h1></ div>
        <div className="faq-marquee">
        <img className="marquee-img" src={imgmarquee} alt="image"/>
        <div className="marquee-container">
         
                    <Marquee speed={200} className="marquee-content "
                        pauseOnHover={true} direction="right">
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                        {/* Repeat the items to ensure smooth scrolling */}
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                    </Marquee>
                </div>
        </div>
        <span className="gap"></span>
        <div className="new-container-div">
          <div className="faq-container">
          
            {faqs.map((faq, index) => (
              <React.Fragment key={index}>
                {index === 0 && <h2>Deposits and Withdrawals</h2>}
                {(index > 0 && index % 5 === 0) && <h2>General Questions</h2>}
                <div
                  className={`faq-item ${activeIndex === index ? "active" : ""}`}
                  key={index}
                >
                  <div
                    className="faq-question"
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.n}
                    <p>{faq.question}</p>
                    <span>
                      {activeIndex === index ? <FaChevronUp  /> : <FaChevronDown className="icon-arrow"/>}
                    </span>
                  </div>
                  <div
                    className={`faq-answer ${activeIndex === index ? "show" : ""}`}
                  >
                    <hr className="vertical-line"/>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
  
  
};

export default FAQ;