import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import avtar from "../images/avtarposter.png"

const ImageCarousel = () => {
  const images = [
    { src:avtar, link: "https://example.com/page1" },
    { src: avtar, link: "https://example.com/page2" },
    { src:  avtar, link: "https://example.com/page3" }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000 
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div className="crousl-admin-div" key={index}>
          <a href={image.link} target="_blank" rel="noopener noreferrer">
            <img src={image.src} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />
          </a>
        </div>
      ))}
    </Slider>
  );
};

export default ImageCarousel;
