import React from "react";
import "./Aboutus.css"
import star from '../images/Star.png'
import Marquee from "react-fast-marquee";
import Header from "../components/Header";
import Footer from "../components/Footer";
import imgmarquee from '../images/marquee-img.png'
import pic1 from "../images/pic1.png"
import pic2 from "../images/pic2.png"
import { aboutdata } from "../data/Aboutdata"
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import newimg from "../images/newimg.png"
import fingering from "../images/finger.png"
import sms from "../images/sms.png"
import share from "../images/share.png"
import returnn from "../images/return.png"

const Aboutus = () => {

    const printdata = aboutdata.map((item, index) => {
        return (
            <div className="team-container" key={index}>
              <MdOutlineKeyboardArrowDown className="down-arow" />
                <div className="circle-div"><img src={item.img} /></div>
                <h3>{item.name}</h3>
                <p>{item.para}</p>
            </div>
        )
    })


    return (<>
        <div className="new-super-div">
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            < div className="faq-heading"><h1>Aboutus</h1></ div>
            <div className="faq-marquee">
                <img className="marquee-img" src={imgmarquee} alt="image" />
                <div className="marquee-container">

                    <Marquee speed={200} className="marquee-content "
                        pauseOnHover={true} direction="right">
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                        {/* Repeat the items to ensure smooth scrolling */}
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Action - packed</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>mind - bending</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>collection of games</h2></div>
                        <div className="marquee-item"><img src={star} alt="star" /> <h2>Gaming spanning</h2></div>
                    </Marquee>
                </div>
            </div>

            <div className="new-container-div2">
                <div className="about-gaming">
                    <div className="text-gaming">
                        <h1 className="vertical-text">
                            <span className="size" >SOMETHING</span>
                            <span className="size">ABOUT</span>
                            <span className="highlight size">GAMING</span>
                        </h1>
                    </div>
                    <div className="pic-gaming">
                        <div className="pic1"><img src={pic1} alt="game image" /></div>
                        <div className="pic2"><img src={pic2} alt="game image" /></div>
                    </div>
                </div>



                <div className="about-gaming2">
                    <h1>OUR <span className="highlight">TEAM</span></h1>
                   <div> <p className="para-gaming">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                     of type and scrambled it to make a type specimen book. </p></div>
                     <div className="team-show-container">{printdata}</div>
                </div>

                <div className="about-gaming3">
             <div className="gaming3-div1">
                <h1>WHAT WE <span className="highlight">PROVIDE</span></h1>
                <div className="two-div-container">
                    <div className="two-div1">
                     <div className="two-img"><img src={fingering}/></div>
                     <p className="heading-two">TOPIC NAME</p>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text </p>
                    </div>
                    <div className="two-div1">
                    <div className="two-img"><img src={sms}/></div>
                     <p className="heading-two">TOPIC NAME</p>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text </p>
                    </div>
                </div>

                <div className="two-div-container">
                    <div className="two-div1">
                     <div className="two-img"><img src={share}/></div>
                     <p className="heading-two">TOPIC NAME</p>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text </p>
                    </div>
                    <div className="two-div1">
                    <div className="two-img"><img src={returnn}/></div>
                     <p className="heading-two">TOPIC NAME</p>
                     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text </p>
                    </div>
                </div>



             </div>
             <div className="gaming3-div2">
                <img src={newimg}/>
             </div>


                </div>

            </div>
        </div>
        <Footer />
    </>)
}
export default Aboutus;