import React from "react";

const Buttons=({btn,setselected,isselected})=>{
    const printdata=btn.map((item,index)=>{
        return <button className={isselected== index?"select-btn btn-d":"btn-d" }
        onClick={()=>setselected(index)}><img src={item.img}/>{item.name}</button>
    })
return(<>
<div className="button-div">
   {printdata}
</div>
</>)
}
export default Buttons