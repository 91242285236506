import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import { jwtDecode } from "jwt-decode"; 
import "./css/Profilescreen.css";
import Header from "../components/Header";
import BASE_URL from "./config/config"; 

function ProfileScreen() {
  const [walletBalance, setWalletBalance] = useState("0");
  const navigate = useNavigate();

  const avatars = {
    avatar_1: require("../assets/avatar/avatar_1.png"),
    avatar_2: require("../assets/avatar/avatar_2.png"),
    avatar_3: require("../assets/avatar/avatar_3.png"),
    avatar_4: require("../assets/avatar/avatar_4.png"),
    avatar_5: require("../assets/avatar/avatar_5.png"),
    uploadAvatar: require("../assets/avatar/upload_avatar.png"),
  };

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("userToken");
        if (token) {
          const decoded = jwtDecode(token);
          console.log("Decoded JWT:", decoded);
          setUserData(decoded);
        } else {
          console.log("No token found, redirecting to home screen");
          navigate("/");
        }
      } catch (error) {
        console.error("Error retrieving or decoding token:", error);
      }
    };
    fetchData();
  }, [navigate]);

  const logoutUser = async () => {
    try {
      localStorage.clear();
      console.log("User logged out successfully");
      navigate("/");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  
  useEffect(()=>{
    const fetchWalletBalance = async () => {
      const token = localStorage.getItem("userToken");
      if (!token) {
        window.alert("Error: You must be logged in to perform this action.");
        return;
      }
  
      try {
        const response = await fetch(`${BASE_URL}api/userdata`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setWalletBalance(data.walletBalance);
      } catch (error) {
        console.error("Error fetching wallet balance:", error);
        window.alert("Error: Failed to fetch wallet balance.");
      }
    };
    fetchWalletBalance();
   },[])

  return (
    <>
    <div className="main-super-profile">
      <Header />
      <div className="main-profile">
        {userData && (
          <div className="profile-container">
            <div className="profile-info">
              <img
                src={avatars[`${userData.avatar}`] || avatars.avatar_1}
                alt="User Avatar"
                className="profile-avatar"
              />
              <h1 >{userData.name}</h1>
              <h3>ID: {userData.userId}</h3>
              <h3>Wallet Balance: {typeof walletBalance === "number"
    ? walletBalance.toFixed(2)
    : "0.00"}</h3>
            </div>
            <div className="profile-options">
              <ul>
                <li onClick={() => navigate("/game-history")}>In Game History</li>
                <li onClick={() => navigate("/deposit")}>Deposit / Add Money</li>
                <li onClick={() => navigate("/withdraw")}>Withdraw</li>
                <li onClick={() => navigate("/transactions")}>Transaction History</li>
                <li onClick={logoutUser}>Logout</li>
              </ul>
            </div>
          </div>
        )}
      </div>
      </div>
    </>
  );
}

export default ProfileScreen;
