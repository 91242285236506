import logo from "./logo.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import SendOtpPage from "./components/SendOtpPage";
import VerifyOtpPage from "./components/VerifyOtpPage";
import ProfileSetupPage from "./components/ProfileSetup";
import ProfileScreen from "./components/Profilescreen";
import AddMoneyScreen from "./components/AddMoney";
import Faq from "./components/Faq";
import Transactions from "./components/Transactions";
import Withdraw from "./components/Withdraw";
import Aboutus from "./pages/Aboutus";
import Createroom from "./components/Createroom";
import RoomScreen from "./components/Rooms";
import JoinRoomScreen from "./components/Joinroom";
import RoomUser from "./components/Roomuser";
import Letsgo from "./components/Letsgo";
import Profile2 from "./components/Profile2";
import Adminroom from "./components/Adminroom";


const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<SendOtpPage />} />
        <Route path="/prelogin" element={<Letsgo/>} />
        <Route path="/otp-screen" element={<VerifyOtpPage />} />
        <Route path="/profile-setup" element={<ProfileSetupPage />} />
        <Route path="/deposit" element={<AddMoneyScreen />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/transactions" element={<Transactions />} /> 
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/create-room" element={<Createroom />} />
        <Route path="/rooms" element={<RoomScreen />} />
        <Route path="/join-room" element={<JoinRoomScreen />} />
        <Route path="/room" element={<RoomUser />} />
        <Route path="/profile" element={<Profile2 />}  />
        <Route path="/adminroom" element={<Adminroom/>}  />
        <Route path="/userroom" element={<RoomUser/>}  />

      </Routes>
    </>
  );
};
export default App;
