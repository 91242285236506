import React, { useState, useEffect } from "react";
import BASE_URL from "./config/config"; 
import "./css/ProfileSetupPage.css"; 
import backlogo from "../images/backlogo.png";
import { Link, useNavigate } from "react-router-dom";
import peopleimg from "../images/peoplelogo.png";
import lin3 from "../images/Line 3.png";
import logo from "../images/logo superwin.png";
export default function ProfileSetupPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    phoneNumber: "",
    uid: "",
    avatar: null,
    memberName: "",
  });

  useEffect(() => {
    // This useEffect block will only run once on component mount
    loadUserData();
  }, []);

  const saveUserData = async (phoneNumber, uid) => {
    try {
      localStorage.setItem("phoneNumber", phoneNumber);
      localStorage.setItem("userId", uid);
      console.log("User data saved to localStorage:", phoneNumber, uid);
    } catch (error) {
      console.error("Error saving user data to localStorage:", error);
    }
  };

  const loadUserData = () => {
    const phoneNumber = localStorage.getItem("phoneNumber");
    const uid = localStorage.getItem("userId");
    if (phoneNumber && uid) {
      setUserData((prev) => ({
        ...prev,
        phoneNumber: phoneNumber,
        uid: uid,
      }));
    } else {
      console.log("No user data in localStorage.");
    }
  };

  const saveProfile = async () => {
    setNameError("");
    setAvatarError("");

    if (!isConfirmed) {
      alert("Please confirm the changes by checking the box.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}avatar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      const data = await response.json();

      localStorage.setItem("userToken", data.token);

      alert("Profile saved successfully!");
      navigate("/"); // You might want to navigate using React Router or any other routing library
    } catch (error) {
      console.error("Save profile error:", error);
      alert("Error saving profile. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const avatars = {
    avatar1: require("../assets/avatar/avatar_1.png"),
    avatar2: require("../assets/avatar/avatar_2.png"),
    avatar3: require("../assets/avatar/avatar_3.png"),
    avatar4: require("../assets/avatar/avatar_4.png"),
    avatar5: require("../assets/avatar/avatar_5.png"),
    uploadAvatar: require("../assets/avatar/upload_avatar.png"),
  };

  const selectAvatar = (key) => {
    const avatarKeys = {
      avatar1: "avatar_1",
      avatar2: "avatar_2",
      avatar3: "avatar_3",
      avatar4: "avatar_4",
      avatar5: "avatar_5",
      uploadAvatar: "upload_avatar",
    };

    setUserData((prev) => ({
      ...prev,
      avatar: avatarKeys[key],
    }));
    setSelectedAvatar(key);
    console.log("Avatar selected:", avatarKeys[key]);
  };

  return (<>
    <div className="abs-ack"> 
                <button className="back-button" onClick={() => navigate(-1)}>
          <img src={backlogo} alt="Back" className="icon" />
         </button>
          <div className="logo-div">
            <Link to="/">
              <img className="logo-size" src={logo} alt="logo" />
            </Link>
          </div>
        </div>
    <div className="main-setup">
      
      <div className="containerprofile">
        <div className="setup-div">
          <h1 className="title">Setup Your Profile
            <img src={lin3} alt="Line 3"/>
          </h1>

          <div className="semi">
            <p className="semi-heading">Select your Avatar</p>
            <p className="semi-heading2">This will display as your profile picture</p>
          </div>

          <div className="avatarContainer">
            {Object.keys(avatars).map((key) => (
              <img
                key={key}
                src={avatars[key]}
                alt={`Avatar ${key}`}
                className={`avatar ${selectedAvatar === key ? "selectedAvatar" : "grayscale"}`}
                onClick={() => selectAvatar(key)}
              />
            ))}
          </div>
          <div className="div-container">
            <p className="blur">UID (this is auto generated)</p>
            <div className="input-container">
              <img src={peopleimg} alt="People Icon"/>
              <input
                type="text"
                value={userData.uid}
                className="input"
                readOnly
              />
            </div>
          </div>
          <div className="div-container">
            <p className="blur">Full Name</p>
            <div className="input-container">
              <img src={peopleimg} alt="People Icon"/>
              <input
                type="text"
                placeholder="Enter your name"
                onChange={(e) =>
                  setUserData({ ...userData, memberName: e.target.value })
                }
                value={userData.memberName}
                className="input"
              />
              
              {nameError ? <span className="errorText">{nameError}</span> : null}
            </div>

            <div className="confirmation-container">
              <input
                type="checkbox"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
                id="confirmationCheckbox"
              />
              <label className="agree" htmlFor="confirmationCheckbox">
                <span className="blur short"> I agree to the</span> Terms & Conditions
              </label>
            </div>

          </div>

          {avatarError ? <span className="errorText">{avatarError}</span> : null}

          <button
            className={
              "login-button long" + (isLoading ? " disabledButton" : "")
            }
            onClick={saveProfile}
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Profile"}
          </button>

        </div>
      </div>
    </div>
    </> );
}
