import React, { useEffect, useState } from "react";
import "./css/Account.css";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import BASE_URL from "../config/config";
import coin from "../../images/coin.png";
import grey from "../../images/grey.png";
import gold from "../../images/gold.png";
import sr from "../../images/sr.png";
import line3 from "../../images/Line 3.png";
import acline from "../../images/acline.png";

const Account = () => {
    const [userData, setUserData] = useState(null);
    const [walletBalance, setWalletBalance] = useState(0);
    const [showCard, setShowCard] = useState(false);

    const navigate = useNavigate();
    const avatars = {
        avatar_1: require("../../assets/avatar/avatar_1.png"),
        avatar_2: require("../../assets/avatar/avatar_2.png"),
        avatar_3: require("../../assets/avatar/avatar_3.png"),
        avatar_4: require("../../assets/avatar/avatar_4.png"),
        avatar_5: require("../../assets/avatar/upload_avatar.png"),
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("userToken");
                if (token) {
                    const decoded = jwtDecode(token);
                    console.log("Decoded JWT:", decoded);
                    setUserData(decoded);
                    setShowCard(true);  // Show the card when user data is set
                } else {
                    console.log("No token found, redirecting to home screen");
                    navigate("/");
                }
            } catch (error) {
                console.error("Error retrieving or decoding token:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            const token = localStorage.getItem("userToken");
            if (!token) {
                window.alert("Error: You must be logged in to perform this action.");
                return;
            }

            try {
                const response = await fetch(`${BASE_URL}api/userdata`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setWalletBalance(data.walletBalance);
            } catch (error) {
                console.error("Error fetching wallet balance:", error);
                window.alert("Error: Failed to fetch wallet balance.");
            }
        };
        fetchWalletBalance();
    }, []);

    if (!userData) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className={`main-card-div ${showCard ? "show" : ""}`}>
                <img src={acline} alt="acline"/>
                <div className="card-details">
                    <div className="account-img">
                        <div className="img-name">
                            <img className="avatar" src={avatars[userData.avatar]} alt="Avatar" />
                        </div>
                        <div className="acc-details">
                            <div className="coin">
                                <img src={coin} alt="coin"/>
                                <h2>{userData.name}</h2>
                            </div>
                            <div className="wr">
                                <p className="grey">UID {userData.userId}</p>
                                <img src={grey} alt="grey"/>
                            </div>
                            <p className="date">Last login: 2024-11-24, 18:46:43</p>
                        </div>
                    </div>
                    <img className="vertical" src={line3} alt="line"/>
                    <div className="card">
                        <div className="share">
                            <div className="name-gold">
                                <div className="goldcoin">
                                    <img src={gold} alt="gold"/>
                                </div>
                                <p>{userData.name}</p>
                            </div>
                            <div className="share-img">
                                <img src={sr} alt="sr"/>
                            </div>
                        </div>
                        <div className="uid">
                            <p>UID  <span>{userData.userId}</span></p>
                        </div>
                        <br /><br /><br />
                        <div className="yellow">
                            <img src={gold} alt="gold"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Account;
